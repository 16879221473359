import Alpine from "alpinejs"
import focus from "@alpinejs/focus"
import collapse from "@alpinejs/collapse"
import mapboxgl from 'mapbox-gl'

declare global {
    interface Window {
        Alpine: typeof Alpine;
    }
}

window.Alpine = Alpine;

Alpine.plugin(focus)
Alpine.plugin(collapse)

Alpine.data("nav", (props: { mobileOpen: boolean, activeMenu: number }) => ({
    mobileOpen: <boolean>props.mobileOpen,
    activeMenu: <number|null>props.activeMenu,
    init() {

    }
}))

Alpine.data("playlist", () => ({
    currentKey: <number|null>null,
    currentPlayer: <HTMLAudioElement|null>null,
    handleUpdate(event: CustomEvent) {
        if (this.currentPlayer && event.detail.key !== this.currentKey) {
            this.currentPlayer?.pause()
        }

        this.currentKey = event.detail.key
        this.currentPlayer = event.detail.player
    }
}))

Alpine.data("player", (props: { key: number, currentPlayer: HTMLAudioElement|null }) => ({
    key: props.key,
    audio: <HTMLAudioElement|null>null,
    duration: 0,
    remaining: 0,
    seekValue: 0,
    volumeValue: .5,
    isPlaying: false,
    isSeeking: false,
    isChangingVolume: false,
    get formattedRemaining() {
        const minutes = Math.floor(this.remaining / 60)
        const seconds = Math.floor(this.remaining % 60)
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    },
    get completedPercentage() {
        if (this.duration > 0) {
            return ((this.duration - this.remaining) / this.duration) * 100
        }
        return 0
    },
    get volumePercentage() {
        if (this.volumeValue > 0) {
            return this.volumeValue * 100
        }
        return 0
    },
    init() {

        const audio = this.$refs.audio as HTMLAudioElement
        audio.addEventListener('loadedmetadata', () => {
            this.duration = audio.duration
            this.remaining = audio.duration
        })

        audio.addEventListener('timeupdate', () => {
            this.remaining = audio.duration - audio.currentTime
        })

        audio.addEventListener('play', () => {
            this.isPlaying = true
            this.updateRemaining()
        })

        audio.addEventListener('pause', () => {
            this.isPlaying = false
        })

        audio.addEventListener('ended', () => {
            this.isPlaying = false
            this.remaining = 0
            this.$dispatch('update-active-player', {'key': this.key })
        })

        this.audio = audio
    },
    updateRemaining() {
        if (this.audio) {
            // Calculate the remaining time
            this.remaining = this.audio.duration - this.seekValue

            if (this.isPlaying) {
                setTimeout( () => this.updateRemaining(), 1000)
            }
        }
    },
    play() {
        this.$dispatch('update-active-player', {'key': this.key, 'player': this.audio})
        this.audio?.play().then()
    },
    pause() {
        this.audio?.pause()
        this.$dispatch('update-active-player', {'key': this.key })
    },
    seekStart() {
        this.isSeeking = true;
        if (this.isPlaying) {
            this.audio?.pause()
        }
    },
    seekEnd() {
        this.isSeeking = false;
        if (this.isPlaying) {
            this.audio?.play().then()
        }
    },
    // Seek function: set playback position based on progress bar click/drag
    seek(event: MouseEvent) {
        if (this.audio) {
            const elem = <HTMLDivElement>event.currentTarget
            const rect = elem.getBoundingClientRect()
            // Get the X coordinate relative to the container
            const x = event.clientX - rect.left
            // Calculate the percentage from the width
            const percentage = x / rect.width
            // Convert percentage to time
            this.seekValue = this.audio.duration * percentage
            // Set the current time
            this.audio.currentTime = this.seekValue

            this.updateRemaining()
        }
    },
    changeVolume(event: MouseEvent) {
        const elem = <HTMLDivElement>event.currentTarget
        const rect = elem.getBoundingClientRect()
        const x = event.clientX - rect.left

        this.volumeValue = Math.max(0, Math.min(1, x / rect.width))

        if (this.audio) {
            this.audio.volume = this.volumeValue
        }
    }
}))

Alpine.data("searchPodcast", () => ({
    form: <HTMLFormElement|null>null,
    init() {
        this.form = this.$el.querySelector('form')
    },
    filterYear() {
        const select = this.$refs.year as HTMLSelectElement
        window.location.href = `/sermons/archive/${select.value}`
    }
}))

Alpine.data("contactForm", () => ({
    form: <HTMLFormElement|null>null,
    errors: {
        from: false,
        email: false,
        message: false
    } as { [key: string]: boolean },
    messages: {
        from: '',
        email: '',
        message: ''
    } as { [key: string]: string },
    validateField(field: HTMLInputElement) {
        if (field.checkValidity()) {
            this.errors[field.name] = false
            this.messages[field.name] = ''
        } else {
            this.errors[field.name] = true
            this.messages[field.name] = field.validationMessage
        }
    },
    init() {
        const form = this.$el.querySelector('form') as HTMLFormElement

        form.noValidate = true
        form.addEventListener('submit', (event) => {

            this.validateField(form.querySelector('[name="from"]') as HTMLInputElement)
            this.validateField(form.querySelector('[name="subject"]') as HTMLInputElement)
            this.validateField(form.querySelector('[name="message"]') as HTMLInputElement)

            if (this.errors.from || this.errors.subject || this.errors.message) {
                event.preventDefault()
            }
        })
    }
}))

Alpine.data("map", (props: { token: string, longitude: number, latitude: number }) => ({
    init() {
        mapboxgl.accessToken = props.token
        const map = new mapboxgl.Map({
            container: this.$el,
            center: [props.longitude, props.latitude],
            zoom: 11,
            interactive: false,
            style: 'mapbox://styles/mapbox/streets-v12'
        });

        const marker = new mapboxgl.Marker()
          .setLngLat([props.longitude, props.latitude])
          .addTo(map)
    }
}))

Alpine.start()